import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  { path: 'review/:id', loadChildren: './review/review.module#ReviewPageModule' },
  { path: 'registro', loadChildren: './registro/registro.module#RegistroPageModule' },
  { path: 'loginmail', loadChildren: './loginmail/loginmail.module#LoginmailPageModule' },
  { path: 'registromail', loadChildren: './registromail/registromail.module#RegistromailPageModule' },
  { path: 'menu', loadChildren: './menu/menu.module#MenuPageModule' },
  { path: 'misreservas', loadChildren: './misreservas/misreservas.module#MisreservasPageModule' },
  { path: 'mapa', loadChildren: './mapa/mapa.module#MapaPageModule' },
  { path: 'reserva-restaurante', loadChildren: './reserva-restaurante/reserva-restaurante.module#ReservaRestaurantePageModule' },
  { path: 'olvide', loadChildren: './olvide/olvide.module#OlvidePageModule' },
  { path: 'resultado-categoria', loadChildren: './resultado-categoria/resultado-categoria.module#ResultadoCategoriaPageModule' },
  { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
  { path: 'policy-terms', loadChildren: './policy-terms/policy-terms.module#PolicyTermsPageModule' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
