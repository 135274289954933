import { Component } from '@angular/core';

import { Platform, Events, NavController, MenuController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UtilsService } from './services/utils.service';
import { Event,NavigationEnd,NavigationExtras, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];
  

  constructor(
    public menu: MenuController,
    private platform: Platform,
    private statusBar: StatusBar,
    public events: Events,
    public navCtrl: NavController,
    private router: Router,
    private utils: UtilsService,
    private storage: Storage
  ) {
    this.initializeApp();
  }

  initializeApp() {

    this.router.events.subscribe((event: Event) => { 
      if (event instanceof NavigationEnd) {
          // Show loading indicator
          var traceName = event.url;
          console.log(" event.urlAfterRedirects", event.urlAfterRedirects);
          gtag('config', 'G-40P7J414J0', 
                   {
                     'page_path': event.urlAfterRedirects
                   }
                  );
          
      }
      
  });


    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      localStorage.setItem("hydra_actual","0");
      // this.utils.loadRestaurantes("restaurantes_08052018", this.utils.apipath+"restaurantes?pagination=true&visible=1&order[fecha]=desc&page="+1).then(get =>{
      //   let reviews = get;
      //   this.storage.set("reviews",JSON.stringify(reviews));
      //   this.storage.set("reviewsobj",reviews);
      //   this.splashScreen.hide();
      // }).catch(()=>{
      //   this.splashScreen.hide();
      // });
    });
  }

  selectOrder(order,asc) {
    this.events.publish('menu:order',order,asc);
  }
  mostrarReserva() {
    this.menu.close("menu1");
    this.events.publish('menu:reservas');
  }
  
  endSession(){
    
    let navigationExtras: NavigationExtras = { 
      state: {
        endsession:true
      }
    };
    this.menu.close("menu1");
    console.log("navigationExtras",navigationExtras)
    this.router.navigate(['/registro'], navigationExtras);
  }
  registrate(){
    this.menu.close("menu1");
    this.navCtrl.navigateForward("/registro");
  }
  misreservas(){
    this.menu.close("menu1");
    this.navCtrl.navigateForward("/misreservas");
  }
  feed(){
    localStorage.setItem("solofav","0");
    this.menu.close("menu1");
    this.navCtrl.navigateForward("/home");
    this.events.publish('menu:feed');
  }
  autentidado(){
    return localStorage.getItem("autenticado") == "1";
  }
  verfavoritos(){
    this.menu.close("menu1");
    console.log("ver favortiso");
    localStorage.setItem("solofav","1");
    this.events.publish('menu:favoritos');
  }
  contactanos() {
    window.open('mailto:alejandro@bogotaeats.com', '_system');
  }
}
